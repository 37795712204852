@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Poppins", "Lora";
  }
}

@keyframes signature {
  100% {
    stroke-dashoffset: 0;
  }
}

.logo {
  stroke: white;
  stroke-width: 100px;
  stroke-dasharray: 3076;
  stroke-dashoffset: 3076;

  animation: signature 2s linear forwards;
}

::-webkit-scrollbar {
  height: 2px;
  width: 6px;
  margin-right: 6px;
}

::-webkit-scrollbar-track {
  background: #18181b;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#ef4444, #9f1239);
}

.squiggle-back {
  background-image: url("../src/assets/images/squiggle.svg");
  background-position: "left";
  background-size: "contain";
  background-repeat: "no-repeat";
}

.random-word-enter {
  opacity: 0;
}
.random-word-exit {
  opacity: 1;
}
.random-word-active {
  opacity: 1;
}
.random-word-active {
  opacity: 0;
}
.random-word-active,
.random-word-active {
  transition: opacity 500ms;
}

.parallax {
  background-attachment: fixed;
  background-position: left;
  background-size: cover;
  object-fit: cover;
  overflow: hidden;
  width: 100vw;
  height:100vh;
  background-repeat: no-repeat;
}

.border-anim::before{
  position:relative;
  border-radius: 20px;
  content: "";
  height:100%;
  width:100%;
  left:-25%;
  top:-25%;
  background-image: conic-gradient( #9f1239 20deg, #ef4444 120deg, transparent 180deg);
  animation: rotate 23s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg)
  }
}